import { useUserService } from 'm6BoBuilder/services/State/User/user.selector';

// ==============================|| AUTH HOOKS ||============================== //

const useAuth = () => {
    const { isActiveUser, isAuth, user, hasRole, logout, refreshToken, rememberMe } = useUserService();

    return { isActiveUser, isAuth, user, hasRole, logout, refreshToken, rememberMe };
};

export default useAuth;
