import TableList from '../components/TableList';
import { ScreenPropsType, ScreenType } from '../types/ScreenType';

const DefaultListScreen: ScreenType = ({ resource }: ScreenPropsType): JSX.Element => (
    <>
        {/* key param required, trigger list refresh */}
        <TableList
            key={resource.resourcePath}
            resource={resource}
            config={resource.configList}
            navigateOnClick={false !== resource.actions?.edit}
        />
    </>
);
export default DefaultListScreen;
