import { Box } from '@mui/material';
import { Puff } from 'react-loader-spinner';
import { useTheme } from '@mui/material';

const CustomLoader = (): JSX.Element => {
    const theme = useTheme();
    return (
        <Box style={{ margin: 20 }}>
            <Puff color={theme.palette.secondary[200]} height={100} width={100} />
        </Box>
    );
};

export default CustomLoader;
