import { Avatar, Chip } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { ROLES, ROLES_OPTIONS, ROLE_COLOR, SERVICE_LABEL, SERVICE_LOGO } from '../../constants';

const getAvatar = (label: string, src: string, alt: string) => {
    return (
        <Avatar alt={alt} src={src}>
            {label}
        </Avatar>
    );
};

const getIcon = (elt: string): any => {
    switch (elt) {
        case ROLES.ROLE_CLIENT_CK:
            return {
                avatar: getAvatar('CK', SERVICE_LOGO.CROQKILOS, SERVICE_LABEL.CROQKILOS),
            };
            break;
        case ROLES.ROLE_CLIENT_CB:
            return {
                avatar: getAvatar('CB', SERVICE_LOGO.CROQBODY, SERVICE_LABEL.CROQBODY),
            };
            break;
        case ROLES.ROLE_CLIENT_YG:
            return {
                avatar: getAvatar('YC', SERVICE_LOGO.YOGICLUB, SERVICE_LABEL.YOGICLUB),
            };
            break;
        case ROLES.ROLE_ADMIN:
            return {
                icon: <PeopleIcon />,
            };
            break;
        case ROLES.ROLE_SUPER_ADMIN:
            return {
                icon: <SupervisorAccountIcon />,
            };
            break;
    }
};

const UserListRoleCell = (elements: []): JSX.Element => {
    return (
        <>
            {elements.map((elt, index) => {
                const role = ROLES_OPTIONS.find((v) => v.value == elt);
                if (ROLE_COLOR.hasOwnProperty(elt)) {
                    const avatar = getIcon(elt);
                    return (
                        <Chip
                            {...avatar}
                            key={index}
                            size="small"
                            label={role?.label}
                            color="primary"
                            sx={{
                                backgroundColor: ROLE_COLOR[elt],
                                color: 'white',
                                marginRight: '5px',
                                marginBottom: '5px',
                                fontWeight: 'bold',
                                fontSize: '0.75rem',
                            }}
                        />
                    );
                }
            })}
        </>
    );
};

export default UserListRoleCell;
