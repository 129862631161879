/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import {
    ApiContext,
    FormEntity,
    FormInput,
    SubFormType,
    FormElementType,
    InputType,
    flashMessage,
    flashType,
    ImageConverter,
} from 'm6BoBuilder';
import { getObjectProperty } from 'm6BoBuilder/utils/getObjectProperty';
import { FormikValues } from 'formik';
import ApiService from '../../../m6BoBuilder/services/ApiService';
import Ingredient from 'config/resources/Ingredient';
import { AxiosError } from 'axios';
import { INGREDIENT_UNITS } from '../../../constants';

type IngredientType = {
    picture: Record<string, undefined>;
};

const SubformRecipeIngredient = ({ position, entity, updateEntity, configLine }: SubFormType): JSX.Element => {
    const apiService: ApiService = useContext(ApiContext);
    const [ingredientData, setIngredientData] = useState<IngredientType | null>(null);
    const getAdornmentFromUnit = (value?: string) => {
        if (!value) return undefined;
        const unit = parseInt(value);

        if (unit !== 0 && INGREDIENT_UNITS[unit]) {
            return <InputAdornment position="end">{INGREDIENT_UNITS[unit]}</InputAdornment>;
        }
    };

    const [subEntity, setSubEntity] = useState(
        entity[configLine.property][position] ?? {
            ingredient: null,
            quantity: null,
            position: null,
        },
    );
    const configIngredient: FormElementType = {
        label: 'Ingrédient',
        property: 'ingredient',
        options: {
            resource: Ingredient.resourcePath,
            details: { path: 'unit', prelabel: '', values: INGREDIENT_UNITS },
        },
    };
    const configQuantity: FormElementType = {
        label: 'Quantité',
        property: 'quantity',
        type: InputType.float,
    };

    useEffect(() => {
        if (entity[configLine.property][position] !== undefined) {
            getIngredientData(entity[configLine.property][position]);
        }
    }, []);

    const getIngredientData = (data: any) => {
        if (data.ingredient?.id) {
            setIngredientData(data.ingredient);
            return;
        }
        const id = data?.ingredient;
        if (null === id || id === undefined) {
            return;
        }
        apiService
            .get(Ingredient.resourcePath, id.split('/').at(-1))
            .then((data) => {
                if (data !== null) {
                    setIngredientData(data.data);
                }
            })
            .catch((error: AxiosError) => {
                flashMessage(error.response?.data['hydra:description'] ?? error.message, flashType.ERROR);
            });
    };

    const updateSubEntity = (updatedSubEntity: FormikValues) => {
        entity[configLine.property][position] = updatedSubEntity;

        setSubEntity(updatedSubEntity);
        updateEntity(entity, configLine.property);
        getIngredientData(updatedSubEntity as any);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={2}>
                {ingredientData
                    ? getObjectProperty(ingredientData, 'media', (ImageConverter.display as any) ?? null)
                    : ''}
            </Grid>
            <Grid item xs={6}>
                <FormEntity
                    entity={subEntity}
                    updateEntity={updateSubEntity}
                    configLine={configIngredient}
                    keepEntity
                />
            </Grid>
            <Grid item xs={3}>
                <FormInput
                    endAdornment={getAdornmentFromUnit(subEntity?.ingredient?.unit)}
                    entity={subEntity}
                    updateEntity={updateSubEntity}
                    configLine={configQuantity}
                />
            </Grid>
        </Grid>
    );
};

export default SubformRecipeIngredient;
