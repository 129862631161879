import { ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';
import { InputType, SelectRenderType } from 'm6BoBuilder';
import SubformMealRecipe from '../../components/form/subform/SubformMealRecipe';

const MealProperties = {
    id: 'id',
    title: 'title',
    type: 'type',
    defaultMealRecipes: 'defaultMealRecipes',
};

const MealTypes = ['Breakfast', 'Collation', 'Lunch', 'Dinner'];

const Meal: ResourceType = {
    resourcePath: 'bo-meals',
    placeHolder: {
        [MealProperties.title]: '',
        [MealProperties.type]: '',
        [MealProperties.defaultMealRecipes]: [],
    },
    validationSchema: yup.object({
        [MealProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Repas',
        listInputs: [
            {
                label: 'Titre',
                property: 'title',
            },
            {
                label: 'Type',
                property: 'type',
                type: InputType.select,
                options: {
                    choices: MealTypes.map((label, index) => {
                        return { value: index, label: label };
                    }),
                    render: SelectRenderType.select,
                },
            },
            {
                label: 'Recettes',
                property: MealProperties.defaultMealRecipes,
                type: InputType.subform,
                options: {
                    subform: SubformMealRecipe,
                    multiple: true,
                },
            },
        ],
    },

    configList: {
        label: 'Type de menu',
        listProperties: [
            {
                label: 'ID',
                property: MealProperties.id,
                sortable: true,
            },
            {
                label: 'Titre',
                property: MealProperties.title,
                sortable: true,
            },
            {
                label: 'Type',
                property: MealProperties.type,
                sortable: true,
                transform: (typeId: number): string => MealTypes[typeId] ?? null,
            },
            {
                label: 'Nbre recettes',
                property: MealProperties.defaultMealRecipes,
                transform: (recipes: []): number => recipes.length,
            },
        ],
        allowAdd: true,
    },
};

export default Meal;
export { MealProperties };
