import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import Moment from '@date-io/moment';
import frLocale from 'date-fns/locale/fr';

import DateConverter from '../../converters/DateConverter';
import { FormElementPropsType } from '../../types/forms/FormElementPropsType';

const MomentUtils = new Moment();
const FormDateTime = ({
    entity,
    updateEntity,
    configLine,
    fieldSize = 'medium',
}: FormElementPropsType): JSX.Element => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const handleDateChange = (date: Date | null) => {
        if (date) {
            entity[configLine.property] = MomentUtils.moment(date).toString();
            updateEntity(entity, configLine.property);
            setSelectedDate(date);
        }
    };

    useEffect(() => {
        if (entity[configLine.property]) {
            setSelectedDate(DateConverter.convert(entity[configLine.property]));
        }
    }, []);
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
            <DateTimePicker
                ampm={false}
                label={configLine.label}
                value={selectedDate}
                renderInput={(params) => <TextField size={fieldSize} {...params} />}
                onChange={(date) => handleDateChange(date)}
            />
        </LocalizationProvider>
    );
};
export default FormDateTime;
