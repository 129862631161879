import React, { /*Dispatch, SetStateAction,*/ useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingScreen from '../../screens/LandingScreen';
import DefaultListScreen from '../../screens/DefaultListScreen';
import DefaultEditScreen from '../../screens/DefaultEditScreen';
import Login from '../../screens/Login';
import Layout from '../../components/layout';
import NotFoundScreen from '../../screens/NotFoundScreen';
import { ScreenType } from '../../types/ScreenType';
import { ConfigContext } from '../../AppBoBuilder';
import { ConfigType } from '../../types/ConfigType';
import useAuth from '../../hooks/useAuth';

const ScreenSwitcher = React.memo((): JSX.Element => {
    const config: ConfigType | null = useContext(ConfigContext);
    const { isAuth, user, hasRole } = useAuth();
    if (!config) {
        throw new Error('Missing initialized config.');
    }
    const routeList: JSX.Element[] = [];

    if (isAuth) {
        config.screens.forEach((screen) => {
            if (hasRole(screen.accessControl.create)) {
                const CreateScreen: ScreenType = screen.createScreen ?? DefaultEditScreen;
                //create
                routeList.push(
                    <Route
                        key={`create_${screen.resource.resourcePath}`}
                        path={`/${screen.resource.resourcePath}/new`}
                        element={<CreateScreen resource={screen.resource} />}
                    />,
                );
            }
            if (hasRole(screen.accessControl.edit)) {
                const DetailScreen: ScreenType = screen.detailScreen ?? DefaultEditScreen;
                //edit
                routeList.push(
                    <Route
                        key={`edit_${screen.resource.resourcePath}`}
                        path={`/${screen.resource.resourcePath}/:id`}
                        element={<DetailScreen resource={screen.resource} />}
                    />,
                );
            }
            if (hasRole(screen.accessControl.list)) {
                const ListScreen: ScreenType = screen.listScreen ?? DefaultListScreen;
                //list
                routeList.push(
                    <Route
                        key={`list_${screen.resource.resourcePath}`}
                        path={`/${screen.resource.resourcePath}`}
                        element={<ListScreen resource={screen.resource} />}
                    />,
                );
            }
        });
    }

    return (
        <Routes>
            {!isAuth ? (
                <Route path="/login" element={<Login />} />
            ) : (
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<LandingScreen />} />
                    {routeList.map((route) => route)}
                    {config.getCustomRouteList(user).map((route) => route)}
                    <Route path="*" element={<NotFoundScreen />} />
                </Route>
            )}
        </Routes>
    );
});
ScreenSwitcher.displayName = 'ScreenSwitcher';

export default ScreenSwitcher;
