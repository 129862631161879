import { List } from '@mui/material';
import { NavigationElementType } from '../../../types/NavigationType';
import NavigationElement from '../../../components/layout/Sidebar/NavigationElement';
import { useContext } from 'react';
import { ConfigContext } from '../../../AppBoBuilder';

const Navigation = (): JSX.Element => {
    const config = useContext(ConfigContext);

    return (
        <List>
            {config &&
                config.navigation.map((configLine: NavigationElementType) => (
                    <NavigationElement key={configLine.title} config={configLine} />
                ))}
        </List>
    );
};

export default Navigation;
