import { Home } from '@mui/icons-material';
import { Breadcrumbs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconChevronRight } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { NavigationElementType } from '../../types/NavigationType';

type NavigationLinksProps = {
    breadcrumbs: {
        prev?: NavigationElementType;
        current?: NavigationElementType;
    };
};

export const NavigationLinks = ({ breadcrumbs }: NavigationLinksProps): JSX.Element => {
    const theme = useTheme();
    return (
        <Breadcrumbs
            sx={{
                '& .MuiBreadcrumbs-separator': {
                    width: 16,
                    ml: 1.25,
                    mr: 1.25,
                    [theme.breakpoints.up('xs')]: {
                        ml: 0,
                        mr: 0,
                    },
                },
            }}
            aria-label="breadcrumb"
            separator={<IconChevronRight stroke={1.5} size="1rem" />}
        >
            <Link to={'/'}>
                <Home color={'secondary'} />
            </Link>
            {breadcrumbs.prev && breadcrumbs.prev.link && (
                <Link to={breadcrumbs.prev.link}>{breadcrumbs.prev.title}</Link>
            )}
            {breadcrumbs.current && <Typography color="text.primary">{breadcrumbs.current?.title}</Typography>}
            {/* {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                return last ? (
                    <Typography style={{ fontSize: '0.8em' }} color="text.primary" key={to}>
                        {breadcrumbNameMap[to]}
                    </Typography>
                ) : (
                    <Link style={{ fontSize: '0.8em' }} to={to} key={to}>
                        {breadcrumbNameMap[to]}
                    </Link>
                );
            })} */}
        </Breadcrumbs>
    );
};
