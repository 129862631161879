import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

// Project import
import { gridSpacing } from '../../berry/store/constant';

const WhiteBox = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(gridSpacing),
    border: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
    background: theme.palette.background.default,
}));

export default WhiteBox;
