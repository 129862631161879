import '../../App.css';

//import { IconLogout } from '@tabler/icons';
import { Toolbar, AppBar, Stack, Avatar, Box /*, Tooltip, Fab, useMediaQuery */ } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// Project import
import useConfig from '../../berry/hooks/useConfig';
//import Customization from '../../berry/layout/Customization';

// assets
import { IconMenu2 } from '@tabler/icons';
import { ReactComponent as Logo } from '../../images/logo-ck.svg';
//import useAuth from 'm6BoBuilder/hooks/useAuth';
//import { useNavigate } from 'react-router-dom';
import ProfileSection from './header/profileSection';

type MainAppBarPropsType = {
    toggleDrawer: () => void;
};

const LogoStyled = styled(Logo)(({ theme }) => ({
    '& .croq': {
        fill: theme.palette.secondary.main,
    },
    '& .bo': {
        fill: theme.palette.text.primary,
    },
}));

export const MainAppBar = ({ toggleDrawer }: MainAppBarPropsType): JSX.Element => {
    const theme = useTheme();
    //const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const { borderRadius } = useConfig();
    //const navigate = useNavigate();
    //const { logout } = useAuth();

    /*const handleLogout = () => {
        logout();
        navigate('/login');
    };*/

    return (
        <AppBar
            enableColorOnDark
            position="absolute"
            color="inherit"
            elevation={0}
            sx={{
                bgcolor: theme.palette.background.default,
                transition: theme.transitions.create('width'),
            }}
        >
            <Toolbar>
                <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    sx={{
                        position: 'fixed',
                        background: theme.palette.background.default,
                        borderRadius: `${borderRadius}px`,
                        px: 1,
                    }}
                >
                    <LogoStyled sx={{ minWidth: 160, marginTop: 1, marginLeft: 1, marginRight: 1 }} />
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            background:
                                theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color:
                                theme.palette.mode === 'dark'
                                    ? theme.palette.secondary.main
                                    : theme.palette.secondary.dark,
                            '&:hover': {
                                background:
                                    theme.palette.mode === 'dark'
                                        ? theme.palette.secondary.main
                                        : theme.palette.secondary.dark,
                                color:
                                    theme.palette.mode === 'dark'
                                        ? theme.palette.secondary.light
                                        : theme.palette.secondary.light,
                            },
                        }}
                        onClick={toggleDrawer}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </Stack>
                <Box sx={{ flexGrow: 1 }} />
                {/*<Tooltip title={'Déconnexion'}>
                    <Fab
                        onClick={handleLogout}
                        size={matchUpMd ? 'medium' : 'small'}
                        variant="circular"
                        color="primary"
                    >
                        <IconLogout color={'white'} />
                    </Fab>
                    </Tooltip>*/}
                {/* profile */}
                <ProfileSection />
            </Toolbar>
        </AppBar>
    );
};
