import { Grid } from '@mui/material';

import { ResourceEditor } from 'm6BoBuilder';

// Resources
import Parameter from 'config/resources/Parameter';

const ParameterScreen = (): JSX.Element => {
    const parameterId = 1;

    return (
        <Grid item xs={6}>
            <ResourceEditor
                id={parameterId}
                resourceType={Parameter.resourcePath}
                config={Parameter.configForm}
                actions={Parameter.actions}
                validationSchema={Parameter.validationSchema}
                placeHolder={Parameter.placeHolder}
            />
        </Grid>
    );
};

export default ParameterScreen;
