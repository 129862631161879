/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { del, get, post, put, getCB } from './ApiManager';
import { HydraResource, HydraList } from 'm6BoBuilder/interfaces/hydra.interface';
import { LoginResponse, PropsLogin } from 'models/User';
import { ApiUser } from 'interfaces/user.interface';
import { CreateResource, UpdateResource } from 'm6BoBuilder/models/Resources';

// RESOURCE //
export const resourceApi = {
    create: (resourcePath: string, data: CreateResource) => post<HydraResource>(`/${resourcePath}`, data),
    getAll: (resourcePath: string, params?: Record<string, any>) =>
        get<HydraList<HydraResource>>(`/${resourcePath}`, { params }),
    getById: (resourcePath: string, resourceId?: string | number, params?: Record<string, any>) =>
        get<HydraResource>(`/${resourcePath}/${resourceId}`, { params }),
    update: (resourcePath: string, resourceId: number, data: UpdateResource) =>
        put<HydraResource>(`/${resourcePath}/${resourceId}`, data),
    delete: (resourcePath: string, resourceId: number) => del(`/${resourcePath}/${resourceId}`),
};

export const resourceCBApi = {
    getAll: (resourcePath: string, params?: Record<string, any>) =>
        getCB<HydraList<HydraResource>>(`/${resourcePath}`, { params }),
    getById: (resourcePath: string, resourceId?: string | number, params?: Record<string, any>) =>
        getCB<HydraResource>(`/${resourcePath}/${resourceId}`, { params }),
};

// USER //
export const userApi = {
    me: () => get<HydraList<ApiUser>>(`/me`),
    login: (credential: PropsLogin) => post<LoginResponse>('/login_check', credential),
    refreshToken: (refreshToken: string) =>
        post<ApiUser>('/token/refresh', {
            refresh_token: refreshToken,
        }),
};
