/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ResourceType, InputType, SelectRenderType, BooleanConverter } from 'm6BoBuilder';
import * as yup from 'yup';
import PromoCode from './PromoCode';
import { OFFER_DURATION_OPTIONS, OFFER_STATE_OPTIONS, SERVICE_OPTIONS } from '../../constants';
import OfferListServiceCell from 'components/offer/OfferListServiceCell';
import UserListPaymentCell from 'components/users/UserListPaymentCell';
import ListDatetimeCell from 'components/table/ListDatetimeCell';
import OfferListStatusCell from 'components/offer/OfferListStatusCell';

const OfferProperties = {
    id: 'id',
    service: 'service',
    duration: 'duration',
    state: 'state',
    source: 'source',
    createdAt: 'createdAt',
    expiredAt: 'expiredAt',
    totalAmount: 'totalAmount',
    transactionId: 'transactionId',
    paymentMethodOrigin: 'paymentMethodOrigin',
    automaticSubscription: 'automaticSubscription',
    promoCode: 'promoCode',
    code: 'code',
    isFreeTrial: 'isFreeTrial',
};

const Offer: ResourceType = {
    resourcePath: 'bo-offers',
    placeHolder: {
        [OfferProperties.service]: 0,
        [OfferProperties.duration]: 1,
        [OfferProperties.state]: '',
        [OfferProperties.source]: '',
        [OfferProperties.createdAt]: new Date(),
        [OfferProperties.totalAmount]: 0,
        [OfferProperties.transactionId]: '',
        [OfferProperties.paymentMethodOrigin]: '',
        [OfferProperties.automaticSubscription]: 0,
        [OfferProperties.promoCode]: null,
        [OfferProperties.code]: '',
        [OfferProperties.isFreeTrial]: false,
    },
    validationSchema: yup.object({
        [OfferProperties.source]: yup.string().required('Ce champ est obligatoire'),
        [OfferProperties.state]: yup.string().required('Ce champ est obligatoire'),
        [OfferProperties.transactionId]: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Abonnement',
        listInputs: [
            {
                label: 'Durée',
                property: OfferProperties.duration,
                type: InputType.select,
                options: {
                    choices: OFFER_DURATION_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
            {
                label: 'Source',
                property: OfferProperties.source,
            },
            {
                label: 'Date de création',
                property: OfferProperties.createdAt,
                type: InputType.date,
            },
            {
                label: 'Service',
                property: OfferProperties.service,
                type: InputType.select,
                options: {
                    choices: SERVICE_OPTIONS,
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Etat',
                property: OfferProperties.state,
                type: InputType.select,
                options: {
                    choices: OFFER_STATE_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
            {
                label: 'Transaction ID',
                property: OfferProperties.transactionId,
                options: {
                    readOnly: false,
                },
            },
            {
                label: 'Code promo',
                property: OfferProperties.promoCode,
                type: InputType.entity,
                options: {
                    resource: PromoCode.resourcePath,
                    label: 'code',
                },
            },
            {
                label: 'Renouvellement auto',
                property: OfferProperties.automaticSubscription,
                type: InputType.select,
                options: {
                    choices: [
                        { value: 0, label: 'Non' },
                        { value: 1, label: 'Oui' },
                    ],
                    render: SelectRenderType.select,
                },
            },
        ],
    },

    configList: {
        label: 'Abonnements',
        listProperties: [
            /*{
                label: '#',
                property: OfferProperties.id,
                sortable: true,
            },*/
            {
                label: 'Service',
                property: OfferProperties.service,
                transform: OfferListServiceCell,
            },
            {
                label: 'Durée (mois)',
                property: OfferProperties.duration,
            },
            /*{
                label: 'Source',
                property: OfferProperties.source,
            },*/
            {
                label: 'Etat',
                property: OfferProperties.state,
            },
            {
                label: 'Origine du paiement',
                property: OfferProperties.paymentMethodOrigin,
                transform: UserListPaymentCell,
            },
            {
                label: 'Montant payé',
                property: OfferProperties.totalAmount,
                transform: (amount: number) => `${amount} €`,
            },
            {
                label: 'Code promo',
                property: OfferProperties.code,
            },
            {
                label: 'Avec essai gratuit',
                property: OfferProperties.isFreeTrial,
                transform: BooleanConverter.display,
            },
            {
                label: 'Renouvellement',
                property: OfferProperties.automaticSubscription,
                transform: BooleanConverter.display,
            },
            {
                label: "Début d'abonnement",
                property: OfferProperties.createdAt,
                transform: ListDatetimeCell,
            },
            {
                label: "Fin d'abonnement",
                property: OfferProperties.expiredAt,
                transform: OfferListStatusCell, //ListDatetimeCell,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default Offer;
export { OfferProperties };
