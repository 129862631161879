import ResourceEditor from '../components/ResourceEditor';
import { ScreenPropsType, ScreenType } from '../types/ScreenType';
import { useParams } from 'react-router-dom';

const DefaultEditScreen: ScreenType = ({ resource }: ScreenPropsType): JSX.Element => {
    const { id } = useParams<string>();
    return (
        <ResourceEditor
            id={id}
            resourceType={resource.resourcePath}
            config={resource.configForm}
            actions={resource.actions}
            validationSchema={resource.validationSchema}
            placeHolder={resource.placeHolder}
        />
    );
};

export default DefaultEditScreen;
