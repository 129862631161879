import * as yup from 'yup';
import { DateConverter, ResourceType, InputType, ImageConverter } from 'm6BoBuilder';

const RecipeAutoPromoProperties = {
    id: 'id',
    title: 'title',
    url: 'url',
    target: 'target',
    label: 'label',
    colorLabel: 'colorLabel',
    media: 'media',
    startDate: 'startDate',
    endDate: 'endDate',
};

const RecipeAutoPromo: ResourceType = {
    resourcePath: 'bo-recipe_auto_promos',
    placeHolder: {
        [RecipeAutoPromoProperties.id]: 0,
        [RecipeAutoPromoProperties.title]: '',
        [RecipeAutoPromoProperties.url]: '',
        [RecipeAutoPromoProperties.target]: undefined,
        [RecipeAutoPromoProperties.label]: '',
        [RecipeAutoPromoProperties.colorLabel]: '',
        [RecipeAutoPromoProperties.media]: undefined,
        [RecipeAutoPromoProperties.startDate]: undefined,
        [RecipeAutoPromoProperties.endDate]: undefined,
    },
    validationSchema: yup.object({
        [RecipeAutoPromoProperties.title]: yup.string().required('Ce champ est obligatoire'),
        [RecipeAutoPromoProperties.startDate]: yup.date().required('Ce champ est obligatoire'),
        [RecipeAutoPromoProperties.endDate]: yup.date().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Block promotionnel privé',
        listInputs: [
            {
                label: 'Titre',
                property: RecipeAutoPromoProperties.title,
            },
            {
                label: 'Phrase',
                property: RecipeAutoPromoProperties.label,
            },
            {
                label: 'Couleur de la phrase',
                property: RecipeAutoPromoProperties.colorLabel,
                type: InputType.colorPicker,
            },
            {
                label: 'Url',
                property: RecipeAutoPromoProperties.url,
            },
            {
                label: 'Ouvrable dans un nouvel onglet',
                property: RecipeAutoPromoProperties.target,
                type: InputType.boolean,
            },
            {
                label: 'Picto',
                property: RecipeAutoPromoProperties.media,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Date de début',
                property: RecipeAutoPromoProperties.startDate,
                type: InputType.date,
            },
            {
                label: 'Date de fin',
                property: RecipeAutoPromoProperties.endDate,
                type: InputType.date,
            },
        ],
    },

    configList: {
        label: 'Block promotionnel privée',
        listProperties: [
            {
                label: 'ID',
                property: RecipeAutoPromoProperties.id,
                sortable: true,
            },
            {
                label: 'Image',
                property: RecipeAutoPromoProperties.media,
                transform: ImageConverter.avatar,
                styles: {
                    width: '15%',
                },
            },
            {
                label: 'Titre',
                property: RecipeAutoPromoProperties.title,
            },
            {
                label: 'Phrase',
                property: RecipeAutoPromoProperties.label,
            },
            {
                label: 'Url',
                property: RecipeAutoPromoProperties.url,
            },
            {
                label: 'Date de début',
                property: RecipeAutoPromoProperties.startDate,
                transform: DateConverter.display,
            },
            {
                label: 'Date de fin',
                property: RecipeAutoPromoProperties.endDate,
                transform: DateConverter.display,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Titre',
                property: RecipeAutoPromoProperties.title,
            },
            {
                label: 'Phrase',
                property: RecipeAutoPromoProperties.label,
            },
            {
                label: 'Couleur',
                property: RecipeAutoPromoProperties.colorLabel,
            },
        ],
    },
    actions: {
        delete: true,
    },
};

export default RecipeAutoPromo;
export { RecipeAutoPromoProperties };
