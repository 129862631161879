import { Grid } from '@mui/material';
import { gridSpacing } from '../berry/store/constant';
/*
import TotalUserCard from '../../components/charts/TotalUserCard';
import UserCountCard from 'm6BoBuilder/berry/ui-component/cards/UserCountCard';
import { IconUserCircle } from '@tabler/icons';

/*import TotalOrderLineChartCard from '../../components/charts/TotalOrderLineChartCard';
import NewUserCard from '../../components/charts/NewUserCard';
import TotalPieChartCard from '../../components/charts/TotalPieChartCard';
import IconNumberCard from '../berry/ui-component/cards/IconNumberCard';*/
//import { useTheme } from '@mui/material/styles';
//import { Help as HelpIcon } from '@mui/icons-material';

function LandingScreen(): JSX.Element {
    //const theme = useTheme();

    return (
        <>
            <Grid container>
                <Grid>
                    <Grid container spacing={gridSpacing}>
                        {/*<Grid item lg={4} md={6} sm={6} xs={12}>
                            <TotalUserCard
                                title={'Total des abonnés'}
                                actions={[
                                    {
                                        endpoint: 'statistics/totalSubs',
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <TotalUserCard
                                title={'Total des utilisateurs'}
                                actions={[
                                    {
                                        endpoint: 'statistics/totalUsers',
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <UserCountCard
                                primary={`primary`}
                                secondary={`secondary`}
                                iconPrimary={IconUserCircle}
                                color={`#654321`}
                            />
                        </Grid>
                        {/*<Grid item lg={4} md={6} sm={6} xs={12}>
                            <TotalPieChartCard
                                title={'Types des codes promos'}
                                actions={[
                                    {
                                        label: 'M',
                                        endpoint: 'statistics/actualPromoCodes',
                                    },
                                    {
                                        label: 'M-1',
                                        endpoint: 'statistics/lastMonthPromoCodes',
                                    },
                                    {
                                        label: 'N-1',
                                        endpoint: 'statistics/lastYearPromoCodes',
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <NewUserCard
                                title={'Nouveaux abonnés'}
                                actions={[
                                    {
                                        label: 'M',
                                        endpoint: 'statistics/actualSubs',
                                    },
                                    {
                                        label: 'M-1',
                                        endpoint: 'statistics/lastMonthSubs',
                                    },
                                    {
                                        label: 'N-1',
                                        endpoint: 'statistics/lastYearSubs',
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <Grid>
                                <TotalOrderLineChartCard
                                    title={'Revenus'}
                                    actions={[
                                        {
                                            label: 'M',
                                            endpoint: 'statistics/thisMonthCA',
                                        },
                                        {
                                            label: 'M-1',
                                            endpoint: 'statistics/lastMonthCA',
                                        },
                                        {
                                            label: 'N-1',
                                            endpoint: 'statistics/lastYearCA',
                                        },
                                    ]}
                                />
                            </Grid>
                            <Grid sx={{ mt: gridSpacing }}>
                                <IconNumberCard
                                    title="Si vous souhaitez afficher quelque chose sur cette page, dites le nous sur Slack :)"
                                    primary={''}
                                    color={theme.palette.primary.dark}
                                    iconPrimary={HelpIcon}
                                />
                            </Grid>
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <TotalPieChartCard
                                title={"Total durées d'abonnements"}
                                actions={[
                                    {
                                        endpoint: 'statistics/totalOfferDurations',
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <NewUserCard
                                title={'Nouveaux utilisateurs'}
                                actions={[
                                    {
                                        label: 'M',
                                        endpoint: 'statistics/actualNewUsers',
                                    },
                                    {
                                        label: 'M-1',
                                        endpoint: 'statistics/lastMonthNewUsers',
                                    },
                                    {
                                        label: 'N-1',
                                        endpoint: 'statistics/lastYearNewUsers',
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <TotalPieChartCard
                                title={'Types de régimes'}
                                actions={[
                                    {
                                        endpoint: 'statistics/totalDietTypes',
                                    },
                                ]}
                            />
                        </Grid>*/}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default LandingScreen;
