import * as yup from 'yup';
import { BooleanConverter, ImageConverter, InputType, ResourceType, SelectRenderType, TagConverter } from 'm6BoBuilder';
import ChoiceConverter from 'm6BoBuilder/converters/ChoiceConverter';
import DietPlan from './DietPlan';
import IngredientCategory, { IngredientCategoryProperties } from './IngredientCategory';
import { INGREDIENT_UNIT_LABELS_OPTIONS as unitLabels } from '../../constants';

const IngredientProperties = {
    id: 'id',
    title: 'title',
    category: 'category',
    weight: 'weight',
    unit: 'unit',
    kcalfor100: 'kcalfor100',
    price: 'price',
    density: 'density',
    inlocker: 'inlocker',
    media: 'media',
    dietPlans: 'dietPlans',
    nbRecipes: 'nbRecipes',
};

const Ingredient: ResourceType = {
    resourcePath: 'bo-ingredients',
    placeHolder: {
        [IngredientProperties.title]: '',
        [IngredientProperties.category]: undefined,
        [IngredientProperties.weight]: 0,
        [IngredientProperties.unit]: '',
        [IngredientProperties.kcalfor100]: 0,
        [IngredientProperties.price]: 0,
        [IngredientProperties.density]: 0,
        [IngredientProperties.inlocker]: false,
        [IngredientProperties.media]: null,
        [IngredientProperties.dietPlans]: [],
    },
    validationSchema: yup.object().shape({
        [IngredientProperties.title]: yup.string().required('Ce champ est obligatoire'),
        [IngredientProperties.category]: yup.string().required('Ce champ est obligatoire').nullable(true),
    }),
    configForm: {
        label: 'Ingrédient',
        listInputs: [
            {
                label: 'Nom',
                property: IngredientProperties.title,
            },
            {
                label: 'Catégorie',
                property: IngredientProperties.category,
                type: InputType.entity,
                options: {
                    label: 'title',
                    resource: IngredientCategory.resourcePath,
                },
            },
            {
                label: 'Régime alimentaire',
                property: IngredientProperties.dietPlans,
                type: InputType.entity,
                options: {
                    resource: DietPlan.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Image',
                property: IngredientProperties.media,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Quantité',
                property: IngredientProperties.weight,
                type: InputType.integer,
            },
            {
                label: 'Unité',
                property: IngredientProperties.unit,
                type: InputType.select,
                options: {
                    choices: unitLabels,
                    render: SelectRenderType.select,
                },
            },
            {
                label: 'Kcal/100g',
                property: IngredientProperties.kcalfor100,
                type: InputType.integer,
            },
            {
                label: 'Prix en € (/kg/L/unité)',
                property: IngredientProperties.price,
                type: InputType.float,
            },
            {
                label: 'Densité',
                property: IngredientProperties.density,
                type: InputType.integer,
            },
            {
                label: 'Placard',
                property: IngredientProperties.inlocker,
                type: InputType.boolean,
            },
        ],
        beforeSubmit: (entity: Record<string, unknown>): Record<string, unknown> => {
            entity.unit = String(entity.unit);
            return entity;
        },
    },
    configList: {
        label: 'Ingrédients',
        listProperties: [
            {
                label: '#',
                property: IngredientProperties.id,
                sortable: true,
                styles: {
                    width: '3%',
                },
            },
            {
                label: 'Image',
                property: IngredientProperties.media,
                transform: ImageConverter.avatar,
                styles: {
                    width: '8%',
                },
            },
            {
                label: 'Ingrédient',
                property: IngredientProperties.title,
                sortable: true,
            },
            {
                label: 'Catégorie',
                property: `${IngredientProperties.category}.title`,
                transform: (cat: string): JSX.Element => TagConverter.display(cat),
                sortable: true,
            },
            {
                label: 'Unité',
                property: IngredientProperties.unit,
                transform: (value: number): string | number => ChoiceConverter.display(value, unitLabels),
            },
            {
                label: 'Kcal/100g',
                property: IngredientProperties.kcalfor100,
            },
            {
                label: 'Nombre de recettes',
                property: IngredientProperties.nbRecipes,
            },
            {
                label: 'Placard',
                property: IngredientProperties.inlocker,
                transform: BooleanConverter.display,
                sortable: true,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: '#ID',
                property: IngredientProperties.id,
                type: InputType.text,
            },
            {
                label: 'Ingrédient',
                property: IngredientProperties.title,
                type: InputType.text,
            },
            {
                label: 'Catégorie',
                property: `${IngredientProperties.category}.id`,
                type: InputType.entity,
                options: {
                    label: IngredientCategoryProperties.title,
                    resource: IngredientCategory.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Placard',
                property: IngredientProperties.inlocker,
                type: InputType.boolean,
            },
        ],
    },
    actions: {
        delete: true,
    },
};

export default Ingredient;
export { IngredientProperties };
