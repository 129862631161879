/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { useMutation } from 'react-query';
import { userApi } from '../services/routes';
import { useAppService } from '../services/State/App/app.selector';
import useAuth from './useAuth';
import { useUserService } from 'm6BoBuilder/services/State/User/user.selector';

export type ApiErrorFrom = 'register';

export const useApiErrorManager = () => {
    const { user } = useAuth();
    const { setIsloadingRefresh, isLoadingRefresh } = useAppService();

    const sendApiErrorManager = (error: unknown, message?: string) => {
        if (!axios.isAxiosError(error)) {
            console.log('erreur interne');
            return;
        }

        if (error.response?.status === 401) {
            if (user && !isLoadingRefresh) {
                setIsloadingRefresh(true);
            } else {
                !isLoadingRefresh && console.log("Erreur lors de l'authentification");
            }
        } else {
            console.log("Une erreur s'est produite", message);
        }
    };
    return sendApiErrorManager;
};

export const useRefreshToken = () => {
    const { setUser, setIsAuth, clearUser } = useUserService();
    const { setIsloadingRefresh } = useAppService();

    return useMutation((refreshToken: string) => userApi.refreshToken(refreshToken), {
        onSuccess: (user) => {
            setUser(user);
            setIsloadingRefresh(false);
        },
        onError: () => {
            console.log('error rft');
            clearUser();
            setIsAuth(false);
            setIsloadingRefresh(false);
        },
    });
};
