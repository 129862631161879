/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import User from 'config/resources/User';
import { TableList, ResourceEditor, MainCard, ActionType, ActionTypeProps } from 'm6BoBuilder';
import { Edit, Close, Add } from '@mui/icons-material';
import { Button, CardContent, IconButton, Grid, Modal, Typography } from '@mui/material';
import { MouseEvent, /*useContext,*/ useEffect, useState } from 'react';
import { RessourceMode } from '../m6BoBuilder/types/ResourceType';
import UserMeasurement from 'config/resources/UserMeasurement';

type UserMeasurementsCardPropsType = {
    userId: number | string;
};

const UserMeasurementsCard = ({ userId }: UserMeasurementsCardPropsType): JSX.Element => {
    const [measurementId, setMeasurementId] = useState(undefined);
    const [editModal, setEditModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    //const [subModal, setSubModal] = useState(false);

    const openEmptyEditModal = () => {
        setMeasurementId(undefined);
        setEditModal(true);
        setRefresh(false);
    };

    const EditMeasurement: ActionType = ({ id }: ActionTypeProps) => {
        const initEditMeasurementForm = (id: any) => (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event?.stopPropagation();
            setMeasurementId(id);
            setEditModal(true);
            setRefresh(false);
        };
        return (
            <IconButton onClick={initEditMeasurementForm(id)} size="large">
                <Edit fontSize="small" />
            </IconButton>
        );
    };

    useEffect(() => {
        if (!editModal) {
            setMeasurementId(undefined);
        }
    }, [editModal]);

    /*useEffect(() => {
        if (subModal) {
            window.location.reload();
        }
    }, [subModal]);*/

    return (
        <Grid
            item
            sx={{
                position: 'relative',
                width: '100%',
                mr: 'auto',
                ml: 'auto',
                mt: '1vh',
                mb: '1vh',
            }}
        >
            <Typography component="h3" variant="h3" sx={{ mt: 4, mb: 4 }}>
                {`Mensurations de l'utilisateur`}
            </Typography>
            <TableList
                resource={UserMeasurement}
                config={UserMeasurement.configList}
                actions={[EditMeasurement]}
                listPath={`${User.resourcePath}/${userId}/${UserMeasurement.resourcePath}`}
                showAddCta={false}
                navigateOnClick={false}
                forceRefresh={refresh}
            />
            {
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    startIcon={<Add />}
                    onClick={openEmptyEditModal}
                >
                    {`Ajouter une mensuration`}
                </Button>
            }

            {editModal && (
                <Modal
                    keepMounted={false}
                    open={editModal}
                    sx={{ overflow: 'scroll' }}
                    onBackdropClick={() => setEditModal(false)}
                >
                    <MainCard
                        sx={{
                            width: '80%',
                            mr: 'auto',
                            ml: 'auto',
                            mt: '5vh',
                            mb: '5vh',
                        }}
                        title={measurementId ? `Editer la mensuration` : `Ajouter une mensuration`}
                        content={false}
                        secondary={
                            <IconButton
                                onClick={() => {
                                    setEditModal(false);
                                }}
                                size="large"
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        }
                    >
                        <CardContent>
                            <ResourceEditor
                                id={measurementId}
                                resourceType={UserMeasurement.resourcePath}
                                config={{
                                    ...UserMeasurement.configForm,
                                    ...{
                                        afterSubmit: (
                                            entity: Record<string, unknown>,
                                            context: RessourceMode,
                                        ): boolean => {
                                            /*if (context === RessourceMode.Create) {
                                                setSubModal(true);
                                                setRefresh(true);
                                                setEditModal(false);
                                            }*/
                                            setRefresh(true);
                                            setEditModal(false);

                                            return false;
                                        },
                                    },
                                }}
                                actions={UserMeasurement.actions}
                                validationSchema={UserMeasurement.validationSchema}
                                placeHolder={UserMeasurement.placeHolder}
                                defaultValues={{
                                    user: `users/${userId}`,
                                }}
                                showHelpCta={false}
                            />
                        </CardContent>
                    </MainCard>
                </Modal>
            )}
        </Grid>
    );
};

export default UserMeasurementsCard;
