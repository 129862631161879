import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';
import BodyParts, { BodyPartsProperties } from 'config/resources/BodyParts';
import UserBodyPartCell from 'components/users/UserBodyPartCell';
import ListDateCell from 'components/table/ListDateCell';

const UserMeasurementProperties = {
    date: 'date',
    size: 'size',
    bodyPart: 'bodyPart',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
};

const UserMeasurement: ResourceType = {
    resourcePath: 'bo-measurements',
    placeHolder: {
        [UserMeasurementProperties.size]: 0,
        [UserMeasurementProperties.date]: new Date(),
        [UserMeasurementProperties.bodyPart]: '',
    },
    validationSchema: yup.object({
        [UserMeasurementProperties.size]: yup
            .number()
            .min(1, 'La valeur doit être superieur à 1')
            .required('Ce champ est obligatoire'),
        [UserMeasurementProperties.date]: yup.date().default(() => new Date()),
        [UserMeasurementProperties.bodyPart]: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Abonnement',
        listInputs: [
            {
                label: 'Parties du corps',
                property: UserMeasurementProperties.bodyPart,
                type: InputType.entity,
                options: {
                    resource: BodyParts.resourcePath,
                    label: BodyPartsProperties.title,
                },
            },
            {
                label: 'Taille (en cm)',
                property: UserMeasurementProperties.size,
                type: InputType.float,
            },
            {
                label: 'Date',
                property: UserMeasurementProperties.date,
                type: InputType.date,
            },
        ],
    },
    configList: {
        label: 'Abonnements',
        listProperties: [
            {
                label: 'Parties du corps',
                property: `${UserMeasurementProperties.bodyPart}.${BodyPartsProperties.title}`,
                transform: UserBodyPartCell,
                styles: {
                    width: '30%',
                },
                sortable: true,
            },
            {
                label: 'Taille (en cm)',
                property: UserMeasurementProperties.size,
                styles: {
                    width: '30%',
                },
                sortable: true,
            },
            {
                label: 'Date',
                property: UserMeasurementProperties.date,
                transform: ListDateCell,
                sortable: true,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default UserMeasurement;
export { UserMeasurementProperties };
