import { ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const RecipeCategoryProperties = {
    id: 'id',
    title: 'title',
    totalRecipes: 'totalRecipes',
};

const RecipeCategory: ResourceType = {
    resourcePath: 'bo-recipe_categories',
    placeHolder: {
        [RecipeCategoryProperties.title]: '',
        [RecipeCategoryProperties.totalRecipes]: 0,
    },

    validationSchema: yup.object({
        [RecipeCategoryProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Catégorie de recette',
        listInputs: [
            {
                label: 'Nom',
                property: 'title',
            },
        ],
    },

    configList: {
        label: 'Catégories de recette',
        listProperties: [
            {
                label: '#',
                property: RecipeCategoryProperties.id,
                sortable: true,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Catégorie',
                property: RecipeCategoryProperties.title,
                sortable: true,
            },
            {
                label: 'Nombre de recettes',
                property: RecipeCategoryProperties.totalRecipes,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Titre',
                property: RecipeCategoryProperties.title,
            },
        ],
    },
    actions: {
        delete: true,
    },
};

export default RecipeCategory;
export { RecipeCategoryProperties };
