import { ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const OfferDurationProperties = {
    id: 'id',
    duration: 'duration',
};

const OfferDuration: ResourceType = {
    resourcePath: 'bo-offer_durations',
    placeHolder: {
        id: 0,
        duration: 0,
    },

    validationSchema: yup.object({
        duration: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: "Durée d'offre",
        listInputs: [
            {
                label: 'Nom',
                property: 'title',
            },
        ],
    },

    configList: {
        label: "Durées d'offres",
        listProperties: [
            {
                label: 'ID',
                property: OfferDurationProperties.id,
                sortable: true,
            },
            {
                label: 'Catégorie',
                property: OfferDurationProperties.duration,
                sortable: true,
            },
        ],
        allowAdd: true,
    },
};

export default OfferDuration;
export { OfferDurationProperties };
