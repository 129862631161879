import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const DietPlanProperties = {
    id: 'id',
    title: 'title',
    totalIngredients: 'totalIngredients',
    totalRecipes: 'totalRecipes',
};

const DietPlan: ResourceType = {
    resourcePath: 'bo-diet_plans',
    placeHolder: {
        [DietPlanProperties.title]: '',
        [DietPlanProperties.totalIngredients]: 0,
        [DietPlanProperties.totalRecipes]: 0,
    },

    validationSchema: yup.object({
        [DietPlanProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Levels',
        listInputs: [
            {
                label: 'Nom',
                property: 'title',
            },
        ],
    },

    configList: {
        label: 'Levels',
        listProperties: [
            {
                label: '#',
                property: DietPlanProperties.id,
                sortable: true,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Régime alimentaire',
                property: DietPlanProperties.title,
                sortable: true,
            },
            {
                label: "Nombre d'ingrédients",
                property: DietPlanProperties.totalIngredients,
            },
            {
                label: 'Nombre de recettes',
                property: DietPlanProperties.totalRecipes,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Régime alimentaire',
                property: DietPlanProperties.title,
                type: InputType.text,
            },
        ],
    },
    actions: {
        delete: true,
    },
};

export default DietPlan;
export { DietPlanProperties };
