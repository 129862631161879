/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, Fragment, /*useContext, */ useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AxiosError } from 'axios';
import { flashMessage, flashType } from '../Flash';
import { FormElementPropsType } from '../../types/forms/FormElementPropsType';
import EntityType from '../../types/EntityType';

import { useGetAllResources } from 'm6BoBuilder/services/useQueries';
import { HydraBase } from 'm6BoBuilder/interfaces/hydra.interface';

type ValueType = { value: unknown; label: string };

type FormEntityPropsType = FormElementPropsType & { keepEntity?: boolean };

const FormEntity = ({
    entity,
    updateEntity,
    configLine,
    fieldSize = 'medium',
    fieldWidth = 'auto',
    keepEntity,
}: FormEntityPropsType): JSX.Element => {
    const resource: string = configLine.options?.resource ?? '';
    const entityIdentifier = configLine.options?.resourceIdentifier ?? '@id';

    // Hooks
    const [selectedValue, setSelectedValue] = useState<ValueType | ValueType[] | null>(
        configLine.options?.multiple ? [] : null,
    );
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [listOptions, setListOptions] = useState<ValueType[]>([]);
    const [queryOptions, setQueryOptions] = useState<Record<string, any>>({});

    // QUERIES
    const {
        isFetching,
        isError,
        error,
        data: { list } = { list: [] },
    } = useGetAllResources(resource, queryOptions, configLine.options?.service);

    // receive path as string like 'window.width' and return value of obj[window][width]
    const resolve = (path: string, obj: Record<string, any>) => {
        return path.split('.').reduce(function (prev: any, curr: any) {
            return prev ? prev[curr] : null;
        }, obj || self);
    };

    const convertFormat = (list: HydraBase[]): ValueType[] => {
        return list.map((element: Record<string, any>): ValueType => {
            let label = element[configLine.options?.label ?? 'title'];
            if (configLine.options?.details) {
                const detail = resolve(configLine.options.details.path, element);
                const itemDetails = `${configLine.options?.details.prelabel}${
                    configLine.options?.details.values ? configLine.options?.details.values[detail] : detail
                }`;
                label = `${label} (${itemDetails})`;
            }

            return {
                value: element[entityIdentifier],
                label: label,
            };
        });
    };

    const handleChange = (_e: ChangeEvent<unknown>, value: ValueType | ValueType[] | null) => {
        const values = Array.isArray(value) ? value.map((element: ValueType) => element.value) : value?.value;
        const updatedEntity = {
            ...entity,
            [configLine.property]: value === null ? value : values,
        };
        updateEntity(updatedEntity, configLine.property);
        setSelectedValue(value);
    };

    const searchOptions = (searchParams?: any) => {
        let queryOptions = {};
        if (searchParams) {
            const { search = '', ...params } = searchParams;
            const options = search == '' ? {} : { [configLine.options?.label ?? 'title']: search };
            queryOptions = { ...params, ...options };
        }
        setQueryOptions(queryOptions);
    };

    useEffect(() => {
        if (!configLine.options?.resource) {
            throw new Error(`Missing resource type in "${configLine.property}" field options`);
        }
        if (entity[configLine.property] != null && !keepEntity) {
            // Reformat subresource (need to be just IRIs for API update)
            let iri: string | string[] = '';
            if (Array.isArray(entity[configLine.property])) {
                iri = entity[configLine.property].map((element: EntityType) => {
                    return element[entityIdentifier];
                });
            } else {
                iri = entity[configLine.property][entityIdentifier];
            }
            entity[configLine.property] = iri;
        }
    }, []);

    useEffect(() => {
        if (isError && error) {
            const axiosError: AxiosError = error as AxiosError;
            flashMessage(axiosError.response?.data['hydra:description'] ?? axiosError.message, flashType.ERROR);
        }
    }, [isError, error]);

    useEffect(() => {
        if (configLine.options?.disableAutocomplete !== true) {
            searchOptions({ search: inputValue });
        }
    }, [inputValue]);

    useEffect(() => {
        // Set default value with query params
        if (list && list.length > 0) {
            const listOptions = convertFormat(list);
            setListOptions(listOptions);
            if (
                (entity[configLine.property]?.length > 0 || entity[configLine.property]?.hasOwnProperty('@id')) &&
                listOptions?.length > 0 &&
                ((Array.isArray(selectedValue) && selectedValue.length === 0) || selectedValue === null)
            ) {
                const values = entity[configLine.property];
                let cleanValues = configLine.options?.multiple ? (!Array.isArray(values) ? [values] : values) : values;
                if (cleanValues.hasOwnProperty('@id')) {
                    cleanValues = cleanValues['@id'];
                }
                const defaultValue = Array.isArray(cleanValues)
                    ? listOptions.filter((option: any) => cleanValues.includes(option.value))
                    : listOptions.find((option: any) => option.value === cleanValues ?? option);

                setSelectedValue(defaultValue ?? selectedValue); // set default values
                if (!Array.isArray(cleanValues) && !defaultValue) {
                    searchOptions({ search: values['title'] });
                }
            }
        }
    }, [list]);

    useEffect(() => {
        // Reset value
        if (
            (entity[configLine.property] === undefined || entity[configLine.property] === '') &&
            selectedValue &&
            selectedValue['value'] !== null
        ) {
            setSelectedValue(configLine.options?.multiple ? [] : null);
        }
    }, [entity]);

    return (
        <Autocomplete
            id={configLine.label}
            sx={{ width: fieldWidth }}
            size={fieldSize}
            multiple={!!configLine.options?.multiple}
            readOnly={!!configLine.options?.readOnly}
            value={selectedValue}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option: ValueType, value) => option.value === value.value}
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.label}
            options={listOptions || []}
            loading={isFetching}
            onChange={handleChange}
            onInputChange={(_event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    //error={error}
                    label={configLine.label}
                    name={configLine.label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
                    }}
                />
            )}
            disableClearable={configLine.options?.disableClearable}
        />
    );
};
export default FormEntity;
