import React, { useContext, useEffect, useState } from 'react';
import { TextField, Stack, CardActions, Box, CardContent, CardHeader, Button } from '@mui/material';
import { styled } from '@mui/material';

import { ApiLoading } from '../AppBoBuilder';
import CustomLoader from '../components/CustomLoader';
import MainCard from '../berry/ui-component/cards/MainCard';
import { ReactComponent as Logo } from '../images/logo-ck.svg';
import { useLogin } from '../services/useQueries';
//import useAuth from '../../hooks/useAuth';

const MainContainer: any = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    width: '100%',
    height: '100vh',
    position: 'relative',
}));

const LogoStyled = styled(Logo)(({ theme }) => ({
    width: 200,
    '& .croq': {
        fill: theme.palette.secondary.main,
    },
    '& .bo': {
        fill: theme.palette.text.primary,
    },
}));

//state type
type State = {
    username: string;
    password: string;
    isButtonDisabled: boolean;
    error: string;
};

const initialState: State = {
    username: '',
    password: '',
    isButtonDisabled: true,
    error: '',
};

const Login = (): JSX.Element => {
    const isApiLoading = useContext(ApiLoading);
    const [username, setUsername] = useState(initialState.username);
    const [password, setPassword] = useState(initialState.password);
    const [error, setError] = useState(initialState.error);
    const [isButtonDisabled, setIsButtonDisabled] = useState(initialState.isButtonDisabled);

    //const { setUser } = useAuth();
    // Queries
    const { mutateAsync, isSuccess: isSuccessLogin } = useLogin();

    useEffect(() => {
        if (username.trim() && password.trim()) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [username, password]);

    useEffect(() => {
        if (isSuccessLogin) {
            console.log('isSuccessLogin');
        }
    }, [isSuccessLogin]);

    const handleLogin = async () => {
        localStorage.removeItem('user');
        try {
            await mutateAsync({ username: username.trim(), password: password.trim() });
        } catch (err) {
            console.log(err); // output: catch error
            setError('Incorrect username or password');
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13 || event.which === 13) {
            isButtonDisabled || handleLogin();
        }
    };

    const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setPassword(event.target.value);
    };

    return (
        <MainContainer>
            <form noValidate autoComplete="off">
                <MainCard
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <CardHeader title={<LogoStyled />} sx={{ textAlign: 'center' }} />
                    <CardContent>
                        <div>
                            <TextField
                                error={error !== ''}
                                fullWidth
                                id="username"
                                type="email"
                                label="Identifiant"
                                placeholder="Identifiant"
                                margin="normal"
                                onChange={handleUsernameChange}
                                onKeyPress={handleKeyPress}
                            />
                            <TextField
                                error={error !== ''}
                                fullWidth
                                id="password"
                                type="password"
                                label="Mot de passe"
                                placeholder="Mot de passe"
                                margin="normal"
                                helperText={error}
                                onChange={handlePasswordChange}
                                onKeyPress={handleKeyPress}
                            />
                        </div>
                    </CardContent>
                    <CardActions>
                        {isApiLoading ? (
                            <Stack alignItems="center" width={'100%'}>
                                <CustomLoader />
                            </Stack>
                        ) : (
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                onClick={handleLogin}
                                disabled={isButtonDisabled}
                            >
                                Se connecter
                            </Button>
                        )}
                    </CardActions>
                </MainCard>
            </form>
        </MainContainer>
    );
};

export default Login;
