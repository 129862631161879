import { StatusOptionType } from './type';

export enum USER_PROMO_PROFILES {
    NEW_CK_USER = 'NEW_CK_USER',
    NEW_CB_USER = 'NEW_CB_USER',
    NEW_YG_USER = 'NEW_YG_USER',
    CK_CLIENT = 'CK_CLIENT',
    CB_CLIENT = 'CB_CLIENT',
    YG_CLIENT = 'YG_CLIENT',
    UNSUBSCRIBED_CK = 'UNSUBSCRIBED_CK',
    UNSUBSCRIBED_CB = 'UNSUBSCRIBED_CB',
    UNSUBSCRIBED_YG = 'UNSUBSCRIBED_YG',
}

export const USER_PROMO_PROFILES_OPTIONS: StatusOptionType[] = (
    Object.keys(USER_PROMO_PROFILES) as Array<keyof typeof USER_PROMO_PROFILES>
).map((key) => {
    return { value: USER_PROMO_PROFILES[key], label: USER_PROMO_PROFILES[key] };
});
