import { useEffect, useState } from 'react';
import { Button, Divider, CardActions, CardContent, Modal, IconButton, Typography, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import MainCard from '../berry/ui-component/cards/MainCard';

type DeleteModalPropsType = {
    modalOpen: boolean;
    handleDelete: () => void;
    closeModal: () => void;
};

const DeleteModal = ({ modalOpen, handleDelete, closeModal }: DeleteModalPropsType): JSX.Element => {
    const [isOpen, setOpen] = useState<boolean>(modalOpen);
    useEffect(() => setOpen(modalOpen), [modalOpen]);

    return (
        <Modal keepMounted={true} open={isOpen} onClose={closeModal}>
            <MainCard
                sx={{
                    position: 'absolute',
                    width: { xs: 280, lg: 450 },
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
                title="Confirmer la suppression"
                content={false}
                secondary={
                    <IconButton onClick={closeModal} size="large">
                        <Close fontSize="small" />
                    </IconButton>
                }
            >
                <CardContent>
                    <Typography variant="body1">Cette action est irréversible.</Typography>
                </CardContent>
                <Divider />
                <CardActions>
                    <Stack justifyContent="flex-end" direction="row" sx={{ flex: 1 }}>
                        <Button type="button" onClick={closeModal} sx={{ marginRight: 3 }}>
                            Annuler
                        </Button>
                        <Button variant="contained" color="error" type="button" onClick={() => handleDelete()}>
                            Effacer
                        </Button>
                    </Stack>
                </CardActions>
            </MainCard>
        </Modal>
    );
};

export default DeleteModal;
