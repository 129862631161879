import { useLocation } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';

// Project imports
import { NavigationLinks } from './navigation/NavigationLinks';
import { NavigationElementType } from '../types/NavigationType';
import WhiteBox from '../components/styled/WhiteBox';
import { ReactElement, useContext } from 'react';
import { ConfigContext } from '../AppBoBuilder';

const PageHeader = (): JSX.Element => {
    const config = useContext(ConfigContext);
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    // Init component
    let Content: ReactElement = <Typography />;

    // Exclude Dashboard
    if (!config || location.pathname === '/') {
        return Content;
    }

    // Get all navigation items with link
    const navItemsWithLinks = config.navigation
        .map((x: NavigationElementType) => {
            if (typeof x.link !== 'undefined') return x;
            if (typeof x.subnav !== 'undefined') return x.subnav;
        })
        .flat();

    // Get current navigation item
    const currentNavItem = navItemsWithLinks.filter((x) => {
        if (x && typeof x.link !== 'undefined') {
            const regex = new RegExp('/' + pathnames[0], 'i');
            if (regex.test(x.link)) {
                return true;
            }
        }
        return false;
    });

    // Build title
    let title = '';
    let breadcrumbs: { prev?: NavigationElementType; current?: NavigationElementType } = {};
    if (typeof currentNavItem[0]?.title !== 'undefined') {
        title = currentNavItem[0].title;
        const lastPath = pathnames[pathnames.length - 1];
        breadcrumbs = {
            current: currentNavItem[0],
        };

        let addTitle: string | undefined;
        let depthFlag = false;

        // Special case : LiveStreamScreen
        if (lastPath === 'stream') {
            addTitle = 'Stream #' + pathnames[pathnames.length - 2];
            depthFlag = true;
        }
        // If navigation item has singular field
        else if (typeof currentNavItem[0].singular !== 'undefined') {
            // Case : add ressource
            if (lastPath === 'new') {
                addTitle = 'Ajouter ' + currentNavItem[0].singular;
                depthFlag = true;
            }

            // Case : edit ressource
            else if (/[0-9]/g.test(lastPath)) {
                addTitle = 'Modifier ' + currentNavItem[0].singular + ' : #' + lastPath;
                depthFlag = true;
            }
        }
        // If more than one level, update breadcrumbs and title
        if (depthFlag) {
            const updatedCurrent = { ...currentNavItem[0] };
            if (typeof addTitle !== 'undefined') {
                updatedCurrent.title = addTitle;
                title = addTitle;
            }
            breadcrumbs = {
                current: updatedCurrent,
                prev: currentNavItem[0],
            };
        }
    }

    Content = (
        <WhiteBox sx={{ p: 2 }}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1}>
                <Grid item>
                    <Typography variant="h3" sx={{ fontWeight: 500 }}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <NavigationLinks breadcrumbs={breadcrumbs} />
                </Grid>
            </Grid>
        </WhiteBox>
    );

    return Content;
};
export default PageHeader;
