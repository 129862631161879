import * as yup from 'yup';
import {
    DateConverter,
    ResourceType,
    InputType,
    SelectRenderType,
    BooleanConverter,
    ImageConverter,
} from 'm6BoBuilder';
import { PUBLISHED_STATUS_OPTIONS } from '../../constants';

const PromotionalMediaProperties = {
    id: 'id',
    bodyMedia: 'bodyMedia',
    publicationDate: 'publicationDate',
    endDate: 'endDate',
    status: 'status',
    position: 'position',
    url: 'url',
    target: 'target',
};

const PromotionalMedia: ResourceType = {
    resourcePath: 'bo-promotional-medias',
    placeHolder: {
        [PromotionalMediaProperties.id]: 0,
        [PromotionalMediaProperties.bodyMedia]: null,
        [PromotionalMediaProperties.publicationDate]: undefined,
        [PromotionalMediaProperties.endDate]: undefined,
        [PromotionalMediaProperties.status]: 0,
        [PromotionalMediaProperties.position]: 1,
        [PromotionalMediaProperties.url]: '',
        [PromotionalMediaProperties.target]: false,
    },

    validationSchema: yup.object({
        [PromotionalMediaProperties.publicationDate]: yup.date().required('Ce champ est obligatoire'),
        [PromotionalMediaProperties.endDate]: yup.date().required('Ce champ est obligatoire'),
        [PromotionalMediaProperties.status]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Medias promotionnels',
        listInputs: [
            {
                label: 'Image',
                property: PromotionalMediaProperties.bodyMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Url',
                property: PromotionalMediaProperties.url,
            },
            {
                label: 'Ouvrable dans un nouvel onglet',
                property: PromotionalMediaProperties.target,
                type: InputType.boolean,
            },
            {
                label: 'Date de publication',
                property: PromotionalMediaProperties.publicationDate,
                type: InputType.date,
            },
            {
                label: 'Date de fin',
                property: PromotionalMediaProperties.endDate,
                type: InputType.date,
            },
            {
                label: 'Position',
                property: PromotionalMediaProperties.position,
                type: InputType.select,
                options: {
                    choices: [
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                        { value: 3, label: '3' },
                    ],
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Status',
                property: PromotionalMediaProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.radio,
                },
            },
        ],
    },

    configList: {
        label: 'Bandeau promotionnel',
        listProperties: [
            {
                label: 'Image',
                property: PromotionalMediaProperties.bodyMedia,
                transform: ImageConverter.display,
            },
            {
                label: 'Publié le',
                property: PromotionalMediaProperties.publicationDate,
                transform: DateConverter.display,
                sortable: true,
            },
            {
                label: 'Fin le',
                property: PromotionalMediaProperties.endDate,
                transform: DateConverter.display,
                sortable: true,
            },
            {
                label: 'Url',
                property: PromotionalMediaProperties.url,
                sortable: true,
            },
            {
                label: 'Publié',
                property: PromotionalMediaProperties.status,
                transform: (element: string): JSX.Element =>
                    BooleanConverter.display(parseInt(element) > 0 ? true : false),
            },
        ],
        filters: [
            {
                label: 'Statut',
                property: PromotionalMediaProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default PromotionalMedia;
export { PromotionalMediaProperties };
