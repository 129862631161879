/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import User from 'config/resources/User';
import { TableList, ResourceEditor, MainCard, ActionType, ActionTypeProps } from 'm6BoBuilder';
import { Edit, Close, Add } from '@mui/icons-material';
import { Button, CardContent, IconButton, Grid, Modal, Typography } from '@mui/material';
import { MouseEvent, /*useContext,*/ useEffect, useState } from 'react';
import { RessourceMode } from '../m6BoBuilder/types/ResourceType';
import UserWeight from 'config/resources/UserWeight';

type UserWeightsCardPropsType = {
    userId: number | string;
};

const UserWeightsCard = ({ userId }: UserWeightsCardPropsType): JSX.Element => {
    const [weightId, setWeightId] = useState(undefined);
    const [editModal, setEditModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    //const [subModal, setSubModal] = useState(false);

    const openEmptyEditModal = () => {
        setWeightId(undefined);
        setEditModal(true);
        setRefresh(false);
    };

    const EditWeight: ActionType = ({ id }: ActionTypeProps) => {
        const initWeightEditForm = (id: any) => (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event?.stopPropagation();
            setWeightId(id);
            setEditModal(true);
            setRefresh(false);
        };
        return (
            <IconButton onClick={initWeightEditForm(id)} size="large">
                <Edit fontSize="small" />
            </IconButton>
        );
    };

    useEffect(() => {
        if (!editModal) {
            setWeightId(undefined);
        }
    }, [editModal]);

    /*useEffect(() => {
        if (subModal) {
            window.location.reload();
        }
    }, [subModal]);*/

    return (
        <Grid
            item
            sx={{
                position: 'relative',
                width: '100%',
                mr: 'auto',
                ml: 'auto',
                mt: '1vh',
                mb: '1vh',
            }}
        >
            <Typography component="h3" variant="h3" sx={{ mt: 4, mb: 4 }}>
                {`Pesées de l'utilisateur`}
            </Typography>
            <TableList
                resource={UserWeight}
                config={UserWeight.configList}
                actions={[EditWeight]}
                listPath={`${User.resourcePath}/${userId}/${UserWeight.resourcePath}`}
                showAddCta={false}
                navigateOnClick={false}
                forceRefresh={refresh}
            />
            <Button color="primary" size="large" variant="contained" startIcon={<Add />} onClick={openEmptyEditModal}>
                {`Ajouter une pesée`}
            </Button>

            {editModal && (
                <Modal
                    keepMounted={false}
                    open={editModal}
                    sx={{ overflow: 'scroll' }}
                    onBackdropClick={() => setEditModal(false)}
                >
                    <MainCard
                        sx={{
                            width: '80%',
                            mr: 'auto',
                            ml: 'auto',
                            mt: '5vh',
                            mb: '5vh',
                        }}
                        title={weightId ? `Editer la pesée` : `Ajouter une pesée`}
                        content={false}
                        secondary={
                            <IconButton
                                onClick={() => {
                                    setEditModal(false);
                                }}
                                size="large"
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        }
                    >
                        <CardContent>
                            <ResourceEditor
                                id={weightId}
                                resourceType={`${User.resourcePath}/${userId}/${UserWeight.resourcePath}`}
                                config={{
                                    ...UserWeight.configForm,
                                    ...{
                                        afterSubmit: (
                                            entity: Record<string, unknown>,
                                            context: RessourceMode,
                                        ): boolean => {
                                            /*if (context === RessourceMode.Create) {
                                                //setSubModal(true);
                                                setRefresh(true);
                                                setEditModal(false);
                                            }*/
                                            setRefresh(true);
                                            setEditModal(false);

                                            return false;
                                        },
                                    },
                                }}
                                actions={UserWeight.actions}
                                validationSchema={UserWeight.validationSchema}
                                placeHolder={UserWeight.placeHolder}
                                defaultValues={{
                                    user: `users/${userId}`,
                                }}
                                showHelpCta={false}
                            />
                        </CardContent>
                    </MainCard>
                </Modal>
            )}
        </Grid>
    );
};

export default UserWeightsCard;
