import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ChangeEvent, useState } from 'react';
import { FormElementPropsType } from '../../types/forms/FormElementPropsType';

const FormSwitch = ({ entity, updateEntity, configLine }: FormElementPropsType): JSX.Element => {
    const [state, setState] = useState(Boolean(entity[configLine.property]));

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        entity[configLine.property] = event.target.checked;
        updateEntity(entity, configLine.property);
        setState(event.target.checked);
    };

    return (
        <FormGroup row>
            <FormControlLabel
                control={
                    <Switch
                        value={entity[configLine.property]}
                        checked={state}
                        onChange={handleChange}
                        name="checkedSwitch"
                    />
                }
                label={configLine.label}
            />
        </FormGroup>
    );
};

export default FormSwitch;
