import ListDateCell from 'components/table/ListDateCell';
import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const UserWeightProperties = {
    createdAt: 'createdAt',
    weight: 'weight',
};

const UserWeight: ResourceType = {
    resourcePath: 'bo-weights',
    placeHolder: {
        [UserWeightProperties.createdAt]: new Date(),
        [UserWeightProperties.weight]: 0,
    },
    validationSchema: yup.object({
        [UserWeightProperties.weight]: yup
            .number()
            .min(1, 'La valeur doit être superieur à 1')
            .required('Ce champ est obligatoire'),
        [UserWeightProperties.createdAt]: yup.date().default(() => new Date()),
    }),
    configForm: {
        label: 'Abonnement',
        listInputs: [
            {
                label: 'Poids',
                property: UserWeightProperties.weight,
                type: InputType.float,
            },
            {
                label: 'Date',
                property: UserWeightProperties.createdAt,
                type: InputType.date,
            },
        ],
    },
    configList: {
        label: 'Abonnements',
        listProperties: [
            {
                label: 'Poids',
                property: UserWeightProperties.weight,
                styles: {
                    width: '60%',
                },
                sortable: true,
            },
            {
                label: 'Date',
                property: UserWeightProperties.createdAt,
                transform: ListDateCell,
                sortable: true,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default UserWeight;
export { UserWeightProperties };
